<app-header></app-header>

<div class="panel panel-default panel-border-color panel-border-color-primary">
  <div class="panel-heading panel-heading-divider">Cadastrar novo Patrimônio</div>
  <div class="panel-body">
    <form #materialForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> Nº do Patrimônio
        </label>
        <div class="col-sm-3">
          <input id="inputNome" type="text" placeholder="Nº Patrimônio" class="form-control" formControlName="numero_patrimonio">
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          Nº do Patrimônio Antigo
        </label>
        <div class="col-sm-3">
          <input id="inputNome" type="text" placeholder="Nº Patrimônio Antigo" class="form-control" formControlName="numero_patrimonio_antigo">
        </div>
      </div>

      <div class="form-group xs-mt-5">
        <label for="inputEmail" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> Categoria
        </label>
        <div class="col-sm-5">
          <select class="select2 form-control" formControlName="codigo_categoria">
            <option *ngFor="let dado of categorias" value="{{dado.codigo_categoria}}">
              {{dado.nome}}
            </option>
          </select>
          <div *ngIf="submitted && f.codigo_categoria.errors" class="obrigatorio">
            <div *ngIf="f.codigo_categoria.errors">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="form-group xs-mt-5">
        <label for="inputEmail" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> Localização
        </label>
        <div class="col-sm-5">
          <select class="select2 form-control" formControlName="codigo_localizacao">
            <option *ngFor="let dado of localizacoes" value="{{dado.codigo_localizacao}}">
              {{dado.nome}}
            </option>
          </select>
          <div *ngIf="submitted && f.codigo_localizacao.errors" class="obrigatorio">
            <div *ngIf="f.codigo_localizacao.errors">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputEmail" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> Descrição
        </label>
        <div class="col-sm-9">
            <textarea cols="10" rows="5" placeholder="Descrição" class="form-control" formControlName="descricao"></textarea>
            <div *ngIf="submitted && f.descricao.errors" class="obrigatorio">
              <div *ngIf="f.descricao.errors">Campo obrigatório</div>
            </div>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> Data da Compra
        </label>
        <div class="col-sm-3">
          <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_compra">
          <app-error-msg [control]="form.get('data_compra')" label="Data de Compra"></app-error-msg>
          <div *ngIf="submitted && f.data_compra.errors" class="obrigatorio">
            <div *ngIf="f.data_compra.errors.required">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> Nº da Nota
        </label>
        <div class="col-sm-3">
          <input id="inputNome" type="text" placeholder="Nº da Nota" class="form-control" formControlName="numero_nota">
          <div *ngIf="submitted && f.numero_nota.errors" class="obrigatorio">
            <div *ngIf="f.numero_nota.errors">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> Nome do Fornecedor
        </label>
        <div class="col-sm-7">
          <input id="inputNome" type="text" placeholder="Nome do Fornecedor" class="form-control" formControlName="nome_fornecedor">
          <div *ngIf="submitted && f.nome_fornecedor.errors" class="obrigatorio">
            <div *ngIf="f.nome_fornecedor.errors">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> Valor
        </label>
        <div class="col-sm-3">
          <input type="text" placeholder="0,00" class="form-control" currencyMask formControlName="valor">
          <div *ngIf="submitted && f.valor.errors" class="obrigatorio">
            <div *ngIf="f.valor.errors.required">Campo obrigatório</div>
          </div>
        </div>
      </div>
      <div class="form-group xs-mt-10" *ngIf="codigo_material">
        <label for="inputNome" class="col-sm-3 control-label">
          Conta Contábil
        </label>
        <div class="col-sm-3">
          <input id="inputNome" type="number" min="0" maxlength="8" placeholder="Conta Contábil" class="form-control" formControlName="conta_contabil">
        </div>
      </div>

      <br />

      <div class="form-group">
          <div class="col-sm-offset-3 col-sm-7">
            <button class="btn btn-space btn-default" routerLink="/patrimonio-listar">Cancelar</button>
            <button type="submit" class="btn btn-space btn-primary" (click)="onSubmit()">Confirmar</button>
          </div>
      </div>

    </form>
  </div>
</div>

<app-footer></app-footer>
